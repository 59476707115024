/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'trash': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M4.5 6a.5.5 0 00-1 0h1zm-1 3a.5.5 0 001 0h-1zm3-3a.5.5 0 00-1 0h1zm-1 3a.5.5 0 001 0h-1zm3-3a.5.5 0 00-1 0h1zm-1 3a.5.5 0 001 0h-1zM1 2.5a.5.5 0 000 1v-1zm10 1a.5.5 0 000-1v1zM3.5 6v3h1V6h-1zm2 0v3h1V6h-1zm2 0v3h1V6h-1zM1 3.5h1.15v-1H1v1zm.65-.5v7h1V3h-1zM11 2.5H9.85v1H11v-1zM9.35 3v7h1V3h-1zm-7.2.5h7.7v-1h-7.7v1zm1 8h5.7v-1h-5.7v1zm6.2-1.5a.5.5 0 01-.5.5v1a1.5 1.5 0 001.5-1.5h-1zm-7.7 0a1.5 1.5 0 001.5 1.5v-1a.5.5 0 01-.5-.5h-1zM4.5 3V2h-1v1h1zM5 1.5h2v-1H5v1zm2.5.5v1h1V2h-1zM7 1.5a.5.5 0 01.5.5h1A1.5 1.5 0 007 .5v1zM4.5 2a.5.5 0 01.5-.5v-1A1.5 1.5 0 003.5 2h1z" _fill="#9DA4C0"/>'
  }
})
